<script lang="ts">
    import { onMount } from 'svelte';
    import Pusher from 'pusher-js';

    export let data: {
        messengerVars: {
            bootFullMessenger: boolean;
            lastContactID: string | false;
            pusherDebug?: boolean;
            pusherCluster?: string;
        };
        socketsDriver: string;
        soketiHost?: string;
        soketiPort?: number;
        useTSL?: boolean;
        key: string;
        pusherKey: string;
        pusherScheme: string;
        user: {
            user_id: number;
        };
        currencySymbol: string;
        creatorCanEarnMoney: boolean;
    };

    onMount(() => {
        console.log(data.pusherKey);
        console.log(data.soketiHost);
        console.log(data.soketiPort);
        console.log(data.pusherScheme);

        let params = {
            cluster: data.messengerVars.pusherCluster,
            wsHost: data.soketiHost,
            wsPort: data.soketiPort,
            forceTLS: data.pusherScheme ? true : false,
        };

        var pusher = new Pusher(data.key, params);
        pusher.logToConsole = true;
        const channel = pusher.subscribe('u57055826586');


        Pusher.log = (msg) => {
            console.log(msg);
        };

        channel.bind('new-message', function (data) {
            console.log(data);
        });
    });
</script>

<div>
    <h1>Hello this is sourov</h1>
</div>
